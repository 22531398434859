import { Chain, ChainUtil, TypeListSEO } from '@dextools/blockchains';
import { CommonUtil } from '@dextools/utils';
import { AppPage } from '../models/app-page.model';
import { AggregatorUtil } from '@dextools/aggregator';

export class AppPageUtil {
  public static isPageAvailableInChain(appPage: AppPage, chain: Chain | null): boolean {
    if (chain && appPage === AppPage.BigSwap && !ChainUtil.getChainData(chain).bigSwapsSupported) {
      return false;
    }

    if (chain && appPage === AppPage.Multiswap && [Chain.Solana, Chain.Filecoin].includes(chain)) {
      return false;
    }
    if (chain && appPage === AppPage.BaseFun && ![Chain.Base, Chain.Binance, Chain.Ethereum, Chain.Arbitrum].includes(chain)) {
      return false;
    }

    if (chain && appPage === AppPage.Stats && [Chain.Filecoin].includes(chain)) {
      return false;
    }

    if (chain && appPage === AppPage.Dextswap && !AggregatorUtil.isChainSupported(chain)) {
      return false;
    }

    if (chain && appPage === AppPage.LiveNewPairs && Chain.Kujira === chain) {
      return false;
    }

    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (appPage !== AppPage.Dashboard && !chain) {
      return false;
    }

    return true;
  }

  public static getAppPageFromUrl(url: string): AppPage | null {
    const chainPages = CommonUtil.convertEnumToArray(AppPage);
    let matchedAppPage: null | AppPage = null;

    for (const chainPage of chainPages) {
      if (chainPage === AppPage.Dashboard) {
        const availableChains = CommonUtil.convertEnumToArray(Chain)
          .map((chain) => chain)
          .join('|');
        const regexChains = `(${availableChains})$`; // the whole string should match
        const regexLanguage = `(${chainPage}([a-z]{2}))`;
        const regexLanguageQuery = `(${chainPage}([a-z]{2}))(\\?.*|\\#.*)?$`;
        const regexUrlParams = `${regexLanguage}${chainPage}(${availableChains})(\\?.*|\\#.*)?$`; // might contain URL params and anchor or not
        const pairListsTypes = CommonUtil.convertEnumToArray(TypeListSEO).join('|');
        const validDashboardUrls = [
          new RegExp(regexChains),
          new RegExp(regexUrlParams),
          new RegExp(regexLanguageQuery),
          new RegExp(pairListsTypes),
        ];

        if (validDashboardUrls.some((validUrlRegex) => validUrlRegex.test(url))) {
          matchedAppPage = chainPage;
          break;
        }
      } else {
        if (new RegExp(chainPage).test(url)) {
          matchedAppPage = chainPage;
          break;
        }
      }
    }

    return matchedAppPage;
  }
}
